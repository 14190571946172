import { configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';

import orderStore from './orderStore';
import userStore from './userStore';

const store = configureStore({
  reducer: {
    order: orderStore,
    user: userStore,
  },
  devTools: true,
});

const initStore = () => store;

export const wrapper = createWrapper(initStore);

export type RootState = ReturnType<typeof store.getState>;
