import React, { useEffect, useState } from 'react';
import { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import { RootState, wrapper } from 'stores/store';
import { deleteCookie, getCookie, hasCookie } from 'cookies-next/lib';
import { authCookieName, anonymousCookieName } from 'helpers/userAuth';
import { setAuthorization, IUserStore } from 'stores/userStore';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderData } from 'stores/orderStore';
import { useRouter } from 'next/router';
import { handleGetCart } from 'services/cartService';
import { createAnonymousToken } from 'helpers/hooks/sendCurrentToken';
import { replaceGeo } from 'services/geoService';
import { AppContextProvider, IAppContext } from 'context/app.context';

import 'styles/globals.scss';
import 'swiper/swiper.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface IAppProps extends AppProps, IAppContext {}

const App: React.FC<IAppProps> = props => {
  const { Component, pageProps } = props;

  const dispatch = useDispatch();
  const userStored = useSelector<RootState, IUserStore>(state => state.user);
  const geoDataStored = userStored.geoData;

  const router = useRouter();
  const { locale: currentLanguage } = router as {
    locale: string;
  };

  const [prevousGeo, setPrevousGeo] = useState(geoDataStored);

  const saveCart = data => {
    if (!data) {
      addEmptyCart();
      return;
    }

    dispatch(setOrderData(data));
  };

  const addEmptyCart = () => {
    dispatch(setOrderData({}));
  };

  useEffect(() => {
    const setCurrentOrder = async () => {
      handleGetCart({
        language: currentLanguage,
        geo: geoDataStored,
        successCallback: saveCart,
        errorCallback: addEmptyCart,
      });
    };

    const setCurrentGeo = async () => {
      replaceGeo({
        data: {
          geo: geoDataStored,
        },
        successCallback: saveCart,
        errorCallback: addEmptyCart,
      });
    };

    if (geoDataStored !== prevousGeo) {
      setPrevousGeo(geoDataStored);
      setCurrentGeo();
      return;
    }

    setCurrentOrder();
  }, [currentLanguage, geoDataStored, getCookie(authCookieName)]);

  useEffect(() => {
    if (hasCookie(authCookieName)) {
      if (!userStored.isAuthorized) {
        dispatch(setAuthorization(true));
      }

      if (hasCookie(anonymousCookieName)) {
        deleteCookie(anonymousCookieName);
      }
    } else if (!hasCookie(anonymousCookieName)) {
      createAnonymousToken();
    }

    if (!hasCookie(authCookieName) && userStored.isAuthorized) {
      dispatch(setAuthorization(false));
    }
  }, [
    getCookie(authCookieName),
    getCookie(anonymousCookieName),
    userStored.isAuthorized,
  ]);

  return (
    <AppContextProvider
      modalType={props.modalType}
      modalMessageData={props.modalMessageData}
      modalSelectData={props.modalSelectData}
      modalAuthAfterSuccess={props.modalAuthAfterSuccess}
    >
      <Component {...pageProps} />
    </AppContextProvider>
  );
};

export default wrapper.withRedux(appWithTranslation(App));
